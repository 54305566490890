import React, { useState } from "react"
import { sendFormData, FormData } from "./util"
import { ContactForm } from "./ContactForm"
import { FormError } from "./FormError"
import { FormSuccess } from "./FormSuccess"

export type FormState = "inactive" | "posting" | "error" | "success"

export const Contact = () => {
  const [formState, setFormState] = useState<FormState>("inactive")

  const onSubmit = async (formData: FormData) => {
    setFormState("posting")
    try {
      await sendFormData(formData)
      setFormState("success")
    } catch (error) {
      console.log(error)
      setFormState("error")
    }
  }

  const showForm = formState !== "success"
  const hasError = formState === "error"
  const isSuccess = formState === "success"
  return (
    <section className="contact">
      <header className="section-header section-header--inverse">
        <h1 className="section-header__title">Heeft u vragen?</h1>
        <p className="section-header__subtitle">
          Neem gerust contact met ons op door middel van het formulier
        </p>
      </header>
      <div className="contact__form">
        {hasError && <FormError />}
        {showForm && <ContactForm onSubmit={onSubmit} formState={formState} />}
        {isSuccess && <FormSuccess />}
      </div>
    </section>
  )
}
