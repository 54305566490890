const CONTACT_FORM_URL =
  "https://25iatomcnb.execute-api.eu-central-1.amazonaws.com/contactForm"

export interface FormData {
  firstName: string
  lastName: string
  company?: string
  email: string
  message: string
}

export const sendFormData = async (formData: FormData) => {
  const body = Buffer.from(JSON.stringify(formData)).toString("base64")
  const response = await fetch(CONTACT_FORM_URL, {
    method: "POST",
    body,
  })
  if (!response.ok) {
    throw new Error(response.statusText)
  }
  console.log(response)
  return response.json()
}
