import { FormState } from "./Contact"
import { useState } from "react"
import { FormData } from "./util"

interface ContactFormProps {
  onSubmit: (data: FormData) => void
  formState: FormState
}

const initialFormData = {
  firstName: "",
  lastName: "",
  email: "",
  company: "",
  message: "",
}

import React from "react"
export const ContactForm: React.FunctionComponent<ContactFormProps> = ({
  onSubmit,
  formState,
}) => {
  const [formData, setFormData] = useState<FormData>(initialFormData)
  const onValueChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [target.name]: target.value,
    })
  }

  const onSubmitHandler = (evt: React.FormEvent) => {
    evt.preventDefault()
    onSubmit(formData)
  }

  const isDisabled = formState === "posting"

  return (
    <form
      onSubmit={onSubmitHandler}
      method="post"
      className="form form--inverse"
    >
      <div className="form__field form__field--inline">
        <label className="form__label" htmlFor="first-name">
          Voornaam*
        </label>
        <input
          disabled={isDisabled}
          onChange={onValueChange}
          className="form__input"
          type="text"
          name="firstName"
          id="first-name"
          placeholder="Voornaam"
          required
          value={formData.firstName}
        />
      </div>
      <div className="form__field form__field--inline">
        <label className="form__label" htmlFor="last-name">
          Achternaam*
        </label>
        <input
          disabled={isDisabled}
          onChange={onValueChange}
          className="form__input"
          type="text"
          name="lastName"
          id="last-name"
          placeholder="Achternaam"
          required
          value={formData.lastName}
        />
      </div>
      <br />
      <div className="form__field form__field--inline">
        <label className="form__label" htmlFor="email">
          E-mail*
        </label>
        <input
          disabled={isDisabled}
          onChange={onValueChange}
          className="form__input"
          type="email"
          name="email"
          id="email"
          placeholder="E-mail"
          required
          value={formData.email}
        />
      </div>
      <div className="form__field form__field--inline">
        <label className="form__label" htmlFor="company">
          Bedrijf
        </label>
        <input
          disabled={isDisabled}
          onChange={onValueChange}
          className="form__input"
          type="text"
          name="company"
          id="company"
          placeholder="Bedrijf"
          value={formData.company}
        />
      </div>
      <div className="form__field">
        <label className="form__label" htmlFor="message">
          Vraag of opmerking*
        </label>
        <textarea
          disabled={isDisabled}
          onChange={onValueChange}
          className="form__input form__textarea"
          name="message"
          id="message"
          placeholder="Vraaf of opmerking"
          required
          value={formData.message}
        ></textarea>
      </div>
      <div className="form__field">
        <button
          disabled={isDisabled}
          type="submit"
          className="button button--call-to-action"
        >
          {isDisabled ? "Gegevens worden verstuurd..." : "Versturen"}
        </button>
      </div>
    </form>
  )
}
