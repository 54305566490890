import React from "react"

export const FormSuccess = () => (
  <div className="contact__success">
    <p>
      Bedankt! Uw gegevens zijn verzonden en wij nemen zo snel mogelijk contact
      met u op.
    </p>
  </div>
)
